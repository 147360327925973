import React from 'react'
import '../styles/Header.css'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div>
        <Link style={{textDecoration: 'none'}} to='/'><div className='topBanner' ></div>
        <div className='logo'>
            <img className='logoImage' src='./images/Vector9.png'/>
            <h5 className='logoName'>KNOUGHT</h5>
        </div>
        </Link>
    </div>
  )
}

export default Header;