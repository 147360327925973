import React from 'react'
import '../styles/SearchError.css'

const SearchError = (props) => {
  const errorMessage = () => {
    if (props.searchQueryCity === "") {
      return (<p>Sorry, but {props.searchQueryProgram} is not available.</p>)
    } else if (props.searchQueryProgram === "") {
      return (<p>Sorry, but {props.searchQueryCity} doesn't have any of the programs you're looking for.</p>)
    } else {
      return (<p>Sorry, but {props.searchQueryProgram} is not available in {props.searchQueryCity}.</p>)
    }
  }

  return (
    <div>
        <div className='searchErrorBox'>
            <h1>Uh-oh :/</h1>
            {errorMessage()}
        </div>
    </div>
  )
}

export default SearchError