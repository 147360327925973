import React from 'react'
import '../styles/SearchResult.css'
import SearchCard from './SearchCard'
import { v4 as uuidv4} from 'uuid'

const SearchResult = (props) => {

    if(props.searchResultData && Array.isArray(props.searchResultData).length > 0) {
        console.log(props.searchResultData)
    }
    let searchCards = [];
    if (props.searchResultData && Array.isArray(props.searchResultData)) {
    searchCards = props.searchResultData.map(result => {
        // console.log(result)
        return(
            <SearchCard
                key={uuidv4()}
                application_deadline={result.application_deadline}
                campus_size={result.campus_size}
                city_name={result.city_name}
                department_name={result.department_name}
                min_entry_gpa={result.min_entry_gpa}
                min_entry_sat={result.min_entry_sat}
                school_name={result.name}
                private_or_public={result.private_or_public}
                rank={result.rank}
                tuition_per_yr_in_state={result.tuition_per_yr_in_state}
                tuition_per_yr_out_of_state={result.tuition_per_yr_out_of_state}
                type_name={result.type_name}
            />
        )
    })
}

  return (
    <div className='parent'>
        {searchCards}
    </div>
  )
}

export default SearchResult