// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerBox {
    height: 50px;
    background-color: #0B7ED2;
    position: fixed;
    bottom: 0;
    /* position: sticky; */
    /* top: 100vh; */
    /* border: 3px solid pink; */
    width: 100%;
}

.socials {
    margin: 0 auto;
    /* border: 2px solid pink; */
    display: flex;
    justify-content: space-around;
    width: 180px;
    padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,SAAS;IACT,sBAAsB;IACtB,gBAAgB;IAChB,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,cAAc;IACd,4BAA4B;IAC5B,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".footerBox {\n    height: 50px;\n    background-color: #0B7ED2;\n    position: fixed;\n    bottom: 0;\n    /* position: sticky; */\n    /* top: 100vh; */\n    /* border: 3px solid pink; */\n    width: 100%;\n}\n\n.socials {\n    margin: 0 auto;\n    /* border: 2px solid pink; */\n    display: flex;\n    justify-content: space-around;\n    width: 180px;\n    padding-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
