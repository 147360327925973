// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-box {
    width: 75%;
    margin: 0 auto;
    margin-top: 35px;
}

.step h3{
    color: #0B7ED2;
    padding: 0; 
    margin: 0;   
}

.step {
    /* border: 3px solid red; */
    display: flex;
}

h3 {
    width: 75px;
}

.step p {
    width: 250px;
    font-size: 16px;
    font-weight: 500;
}

.step:not(:nth-child(4)) {
    margin-bottom: 20px;
}

@media(min-width : 1233px) {
    .steps-box {
        width: 21%;
    }
}

@media(min-width : 1920px) {
    .steps-box {
        width: 15%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/SearchInstructions.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,SAAS;AACb;;AAEA;IACI,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".steps-box {\n    width: 75%;\n    margin: 0 auto;\n    margin-top: 35px;\n}\n\n.step h3{\n    color: #0B7ED2;\n    padding: 0; \n    margin: 0;   \n}\n\n.step {\n    /* border: 3px solid red; */\n    display: flex;\n}\n\nh3 {\n    width: 75px;\n}\n\n.step p {\n    width: 250px;\n    font-size: 16px;\n    font-weight: 500;\n}\n\n.step:not(:nth-child(4)) {\n    margin-bottom: 20px;\n}\n\n@media(min-width : 1233px) {\n    .steps-box {\n        width: 21%;\n    }\n}\n\n@media(min-width : 1920px) {\n    .steps-box {\n        width: 15%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
