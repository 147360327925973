// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topBanner {
    height: 50px;
    background-color: #0B7ED2;
}

.logo {
    margin: 0 auto;
    /* border: 3px solid pink; */
    width: 80px;
    /* align-items: center; */
    margin-top: 30px;
    margin-bottom: 17px;
}

.logoName {
    color: #0B7ED2;
    font-weight: bold;
    margin: 0px;

}

@media(min-width : 1233px) {
    img.logoImage {
        width: 91px;
        height: 44.57px;
        margin-top: 40px;
    }

    .topBanner {
        height: 0px;
    }

    .logoName {
        font-size: 20px;
        margin-bottom: 25px;
    }

}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,4BAA4B;IAC5B,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,WAAW;;AAEf;;AAEA;IACI;QACI,WAAW;QACX,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;;AAEJ","sourcesContent":[".topBanner {\n    height: 50px;\n    background-color: #0B7ED2;\n}\n\n.logo {\n    margin: 0 auto;\n    /* border: 3px solid pink; */\n    width: 80px;\n    /* align-items: center; */\n    margin-top: 30px;\n    margin-bottom: 17px;\n}\n\n.logoName {\n    color: #0B7ED2;\n    font-weight: bold;\n    margin: 0px;\n\n}\n\n@media(min-width : 1233px) {\n    img.logoImage {\n        width: 91px;\n        height: 44.57px;\n        margin-top: 40px;\n    }\n\n    .topBanner {\n        height: 0px;\n    }\n\n    .logoName {\n        font-size: 20px;\n        margin-bottom: 25px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
