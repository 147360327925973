import React from 'react'
import '../styles/ExpandedResult.css'
import { v4 as uuidv4} from 'uuid'
import { Link } from 'react-router-dom'
import Major from './Major'

const ExpandedResult = (props) => {

    let majorLinks = []
    if (props.majorData && Object.keys(props.majorData).length > 0) {
        majorLinks = props.majorData.map(result => {
            // console.log(result)
            return(
                <Major
                    key={uuidv4()}
                    majorName={result.name}
                    majorLink={result.address}
                />
            )
        })
    }

  return (
    <div className='expandedResult' id='#top'>
        <div className='resultSearchCard'>
            <div className='resultUniInfo'>
                <p className='resultProgramName emphasis'>{props.resultData.type_name}</p>
                <p className='resultDepartmentName'>{props.resultData.department_name}</p>
                <p className='resultUniversityName'>{props.resultData.name}</p>
                <p className='city'>{props.resultData.city_name}</p>
            </div>
            <div className='resultRankInfo'>
                <p className='emphasis'>Ranked #{props.resultData.rank}</p>
                <p>in Business Programs</p>
            </div>
            <div className='resultMainInfo'>
                <div className='resultLine1'>
                    <div className='resultOutOfState'>
                        <p className='resultOutOfStateCost emphasis'>{props.resultData.tuition_per_yr_out_of_state}</p>
                        <p>Out-Of-State/Year</p>
                    </div>
                    <div className='resultApplyBefore'>
                        <p className='emphasis'>Jan 5</p>
                        <p>Apply Before</p>
                    </div>
                </div>
                <div className='resultLine2'>
                    <div className='resultInState'>
                        <p className='resultInStateCost emphasis'>{props.resultData.tuition_per_yr_in_state ? props.resultData.tuition_per_yr_in_state : 'N/A'}</p>
                        <p>In-State/Year</p>
                    </div>
                    <div className='resultMiscDetails'>
                        <p className='resultPublicOrPrivate emphasis'>{props.resultData.private_or_public}</p>
                        <p className='resultCampusSize'>{props.resultData.campus_size} University</p>
                    </div>
                </div>
                <div className='resultGrades'>
                    <div className='resultGradeLine1'>
                        <p className='resultGpa'>GPA Required: <b>{props.resultData.min_entry_gpa}</b></p>
                        <p className='resultSat'>SAT Required: <b>{props.resultData.min_entry_sat}</b></p>
                    </div>
                    <div className='resultGradeLine2'>
                        <p className='resultDuration'>Program Duration: <b>{props.resultData.duration} Years</b></p>
                        <p className='resultAcceptanceRate'>Acceptance Rate: <b>{props.resultData.acceptance_rate}%</b></p>
                    </div>
                </div>
            </div>
            <a href={props.resultData.application_link} target="_blank" rel="noopener noreferrer"><button className='btn-apply'>Apply</button></a>
            <div className='visitProgram'>
                <p className='or emphasis'>OR</p>
                <a href={props.deptLink.address} target="_blank" rel="noopener noreferrer"><p className='readMore visit'>visit program page to learn more...</p></a>
            </div>
            <div className='overview'>
                <p className='title emphasis'>Course Overview</p>
                <p className='overviewContent'>{props.resultData.description}</p>
            </div>
            <div className='majors'>
                <p className='title emphasis'>Majors:</p>
                <div className='alternatives'>
                    {majorLinks}
                </div>
            </div>
        </div>
        <a href='#top'><p className='readMore toTop'>back to top</p></a>
    </div>
  )
}

export default ExpandedResult