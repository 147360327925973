// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchErrorBox {
    width: 320px;
    margin: 0 auto;
    text-align: center;
    /* border: 3px dotted #0B7ED2; */
}`, "",{"version":3,"sources":["webpack://./src/styles/SearchError.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,gCAAgC;AACpC","sourcesContent":[".searchErrorBox {\n    width: 320px;\n    margin: 0 auto;\n    text-align: center;\n    /* border: 3px dotted #0B7ED2; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
