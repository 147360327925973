import React from 'react'
import { Link } from 'react-router-dom'

const SearchCard = (props) => {
  return (
    <div>
        <div className='searchCard'>
            <div className='uniInfo'>
                <p className='programName emphasis'>{props.type_name}</p>
                <p className='departmentName universityName'>{props.department_name}</p>
                <p className='universityName'>{props.school_name}</p>
                <p className='city'>{props.city_name}</p>
            </div>
            <div className='rankInfo'>
                <p className='emphasis'>Ranked #{props.rank}</p>
                <p>in Business Programs</p>
            </div>
            <div className='mainInfo'>
                <div className='line1'>
                    <div className='outOfState'>
                        <p className='outOfStateCost emphasis'>{props.tuition_per_yr_out_of_state}</p>
                        <p>Out-Of-State/Year</p>
                    </div>
                    <div className='applyBefore'>
                        <p className='emphasis'>{props.application_deadline ? props.application_deadline : 'Rolling'}</p>
                        <p>Apply Before</p>
                    </div>
                </div>
                <div className='line2'>
                    <div className='inState'>
                        <p className='inStateCost emphasis'>{props.tuition_per_yr_in_state ? props.tuition_per_yr_in_state : 'N/A'}</p>
                        <p>In-State/Year</p>
                    </div>
                    <div className='miscDetails'>
                        <p className='publicOrPrivate emphasis'>{props.private_or_public}</p>
                        <p className='campusSize'>{props.campus_size} University</p>
                    </div>
                </div>
                <div className='grades'>
                    <p className='gpa'>GPA Required: <b>{props.min_entry_gpa}</b></p>
                    <p className='sat'>SAT Required: <b>{props.min_entry_sat}</b></p>
                </div>
            </div>
            <p className='readMore'><Link to={`result/${props.department_name}`}>
                {""}
                read more
            </Link>
            </p>
        </div>
    </div>
  )
}

export default SearchCard