import React from 'react'
import PrimarySearch from './PrimarySearch'
import SearchResult from './SearchResult'
import SearchError from './SearchError'
import SearchInstructions from './SearchInstructions'
import { useOutletContext } from 'react-router-dom'

const SearchResults = () => {
    const [displaySearchError, setDisplaySearchError] = React.useState(false)
    const [searchResultData, setSearchResultData] = React.useState({})
    const [displaySearchResults, setDisplaySearchResults] = React.useState(false)
    const [searchQuery, setSearchQuery] = React.useState({
        city: "",
        program: ""
    })
    const [programs, setPrograms,
        cities, setCities] = useOutletContext();
    
    return (
    <div>
        <PrimarySearch
            programs={programs}
            cities={cities}
            displaySearchResults={displaySearchResults}
            setDisplaySearchResults={setDisplaySearchResults}
            setSearchResultData={setSearchResultData}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setDisplaySearchError={setDisplaySearchError}
            />
        {displaySearchResults && searchResultData &&
        <SearchResult
            searchResultData={searchResultData}
         />}
        {displaySearchError && displaySearchResults && (searchResultData.length === 0 ? <SearchError
            searchQueryCity={searchQuery.city}
            searchQueryProgram={searchQuery.program}
        /> : "")}
        {!displaySearchResults && <SearchInstructions/>}
    </div>
  )
}

export default SearchResults