import React, { useEffect, useState } from 'react'
import ExpandedResult from './ExpandedResult'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const ExpandedResults = () => {
    const [resultData, setResultData] = useState({})
    const [majorData, setMajorData] = useState({})
    const [deptLink, setDeptLink] = useState({})
    const params = useParams();
    // console.log(params)

    const getMajorData = async (departmentName) => {
        let Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/resultRoute/major/${departmentName}`);
        // console.log(Response)
        setMajorData(Response.data);
    }

    const getDepartmentLink = async (departmentName) => {
        let Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/resultRoute/departmentLink/${departmentName}`);
        // console.log(Response)
        setDeptLink(Response.data[0]);
    }

    const getExpandedResultData = async () => {
        // let data = params;
        let Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/resultRoute/${params.result}`);
        // console.log(Response);
        setResultData(Response.data[0]);
        getMajorData(Response.data[0].department_name);
        getDepartmentLink(Response.data[0].department_name);
    }

    useEffect(() => {
        getExpandedResultData();
      }, []);
    

  return (
    <div>
        <ExpandedResult
            resultData={resultData}
            majorData={majorData}
            deptLink={deptLink}
        />
    </div>
  )
}

export default ExpandedResults