import React from 'react'
import '../styles/SearchInstructions.css'

const SearchInstructions = () => {
  return (
    <div className='steps-box'>
        <h2>How To Use Knought</h2>
        <div>
            <div className='step'>
                <h3>Step 1.</h3>
                <p>Type in the undergraduate program you are looking for AND...</p>
            </div>
            <div className='step'>
                <h3>Step 2.</h3>
                <p>Enter the city to find that program in the city. OR... </p>
            </div>
            <div className='step'>
                <h3>Step 3.</h3>
                <p>Leave city empty to see all relevant programs in the U.S.</p>
            </div>
            <div className='step'>
                <h3>Step 4.</h3>
                <p>...OR, leave programs empty and enter a city to see all programs in that city.</p>
            </div>
        </div>
    </div>
  )
}

export default SearchInstructions