import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from './error-page';
import SearchResults from './components/SearchResults'
import SearchResult from './components/SearchResult';
import PrimarySearch from './components/PrimarySearch';
import ExpandedResult from './components/ExpandedResult';
import ExpandedResults from './components/ExpandedResults';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App/>}>
          <Route index element={<SearchResults/>}/>
          <Route path='result/:result' element={<ExpandedResults/>}>
            {/* <Route path=':result' element={<ExpandedResults/>}/> */}
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
