import React from 'react'
import '../styles/PrimarySearch.css'
import { v4 as uuidv4} from 'uuid'
import axios from 'axios'


const PrimarySearch = (props) => {
    const [resultsPresent, setResultsPresent] = React.useState(false)
    // const [props.searchQuery, props.setSearchQuery] = React.useState({
    //     city: "",
    //     program: ""
    // })

    const displaySearch = () => {
        if (props.searchQuery.city.length > 0 || props.searchQuery.program.length > 0) {
            props.setDisplaySearchResults(true);
        }
    }

    const quickFillField = (field, value) => {
        props.setSearchQuery((prevSearchQuery) => {
            return {
                ...prevSearchQuery,
                [field]: value
            };
        });
    }

    const checkForSearchFieldCompletion = async () => {
        let data;
        let Response;
        if (props.searchQuery.city.length > 0 && props.searchQuery.program.length > 0) {
            data = props.searchQuery;
            // console.log(data)
            Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/searchRoute/searchByProgramAndCity`, {params: data});
            console.log(Response)
        } else if (props.searchQuery.program.length > 0 && props.searchQuery.city === "") {
            data = props.searchQuery;
            // console.log(data);
            Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/searchRoute/searchByProgramField`, {params: data});
            // console.log(Response)
        } else if (props.searchQuery.city.length > 0 && props.searchQuery.program === "") {
            data = props.searchQuery;
            // console.log(data);
            Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/searchRoute/searchByCityField`, {params: data});
        }
        if (Response && Response.data) {
            // Assuming Response.data contains the required search results
            props.setSearchResultData(Response.data);
        }
    } 

    const submitQueryData = (event) => {
        event.preventDefault()
        // console.log(props.searchQuery)
        displaySearch()
        checkForSearchFieldCompletion()
        props.setDisplaySearchError(true)
    }

    const handleChange = (event) => {
        props.setDisplaySearchError(false)
        props.setSearchQuery((prevSearchQuery) => {
            return {
                ...prevSearchQuery,
                [event.target.name]: event.target.value,
            };
        });
    };
 
    if (props) {
    // console.log(props.programs)
    }

    return (
        <div>
            <div className='searchInputs'>
                <form onSubmit={submitQueryData}>
                    <label htmlFor='program'>Program</label>
                    <div className='programInput'>
                        <div><img className='inputIcon' src='./icons/search_24px_rounded.svg'/></div>
                        <input
                            type='text'
                            id='program'
                            className="program"
                            placeholder="Bachelors in Business Administration"
                            name="program"
                            onChange={handleChange}
                            value={props.searchQuery.program}
                        />
                    </div>
                    <div className='dropdown 1'>
                        {props.programs.filter(item => {
                            const searchProgramTerm = props.searchQuery.program.toLowerCase();
                            const programName = item.name.toLowerCase();

                            return searchProgramTerm && programName.includes(searchProgramTerm) && programName !== searchProgramTerm;
                        }).slice(0,10)
                        .map((item) => {
                           return <div onClick={()=> quickFillField('program', item.name)} 
                           className='dropdown-row'
                           key={uuidv4()}
                           >
                            {item.name}
                            </div>
                        })}
                    </div>
                    <div  className='city-btn'>
                        <div>
                        <label htmlFor='city'>City</label>
                            <div className='cityInput'>
                                <div><img className='inputIcon' src='./icons/location_on_24px_rounded.svg'/></div>
                                <input
                                    type='text'
                                    id='city'
                                    className="city"
                                    placeholder="Austin"
                                    name="city"
                                    onChange={handleChange}
                                    value={props.searchQuery.city}
                                />
                            </div>
                            <div className='dropdown 2'>
                                {props.cities.filter(item => {
                                const searchCityTerm = props.searchQuery.city.toLowerCase();
                                const cityName = item.name.toLowerCase();

                                        return searchCityTerm && cityName.includes(searchCityTerm) && cityName !== searchCityTerm;
                                    }).slice(0,10)
                                    .map((item) => {
                                    return <div onClick={()=> quickFillField('city', item.name)} 
                                    className='dropdown-row'
                                    key={uuidv4()}
                                    >
                                        {item.name}
                                        </div>
                            })}
                            </div>
                        </div>
                        <button className='btn'>Find Programs</button>
                    </div>
                </form>
            </div>
            {props.displaySearchResults && <div className='pseudo'></div>}
        </div>
    )
}

export default PrimarySearch;