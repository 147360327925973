import React from 'react'
import '../styles/Footer.css'

const Footer = () => {
  return (
    <div>
        <div className='footerBox'>
            <div className='socials'>
                {/* <img src='/socials/DiscordLogo.png'/> */}
                <a href='mailto:contactknought@gmail.com'><img src='/socials/EnvelopeSimple.png'/></a>
                <a href='https://www.reddit.com/r/Knought4Students/'><img src='/socials/RedditLogo.png'/></a>
            </div>
        </div>
    </div>
  )
}

export default Footer