import React from 'react'
import { Link } from 'react-router-dom'

const Major = (props) => {
  return (
    <div className='majorTabBox'>
        <a href={props.majorLink} target="_blank" rel="noopener noreferrer"><p className='alternativeMajors readMore'>{props.majorName}</p></a>
    </div>
  )
}

export default Major