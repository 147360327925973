// import './App.css';
// import './styles/PrimarySearch.css'
import axios from 'axios'
import React from 'react';
import PrimarySearch from './components/PrimarySearch';
import Header from './components/Header';
import Footer from './components/Footer';
import SearchResult from './components/SearchResult';
import ExpandedResult from './components/ExpandedResult';
import { Outlet } from 'react-router-dom';


function App() {
  let [displaySearch, setDisplaySearch] = React.useState(true);
  let [programs, setPrograms] = React.useState([]);
  let [cities, setCities] = React.useState([]);

  // console.log(process.env.REACT_APP_ORIGIN)

  const getProgramSearchData = async () => {
    let Response;
    Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/searchRoute/searchProgram`).then((response) => {
      // console.log(response.data);
      setPrograms(response.data)
    })

  }

  const getCitySearchData = async () => {
    let Response
    Response = await axios.get(`${process.env.REACT_APP_ORIGIN}/api/searchRoute/searchCity`).then((response) => {
      // console.log(response)
      setCities(response.data);
    })
  }

  React.useEffect(() => {
    getProgramSearchData();
    getCitySearchData();
  }, [])


  return (
    <div className="App">
      <Header/>
      <Outlet
        context={[programs, setPrograms,
          cities, setCities]}/>
      <Footer/>
    </div>
  );
}

export default App;
